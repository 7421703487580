import styled from "@emotion/styled"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Benefits = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1028px;
  margin: 64px auto;

  @media (min-width: 1024px) {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Box = styled.div`
  text-align: center;
  max-width: 300px;

  :nth-of-type(2) {
    margin: 64px auto;
  }

  h3 {
    color: ${theme => theme.dark};
    margin: 32px 0 0;
  }

  p {
    color: ${theme => theme.dark};
    margin: 0;
  }

  @media (min-width: 1024px) {
    :nth-of-type(2) {
      margin: 0;
    }
  }
`

export default function SedationBenefits() {
  return (
    <Benefits>
      <Box>
        <StaticImage
          src="../images/reduce-anxiety.png"
          alt="reduce anxiety"
          placeholder="blurred"
        />
        <h3>Reduce Anxiety</h3>
        <p>
          Enjoy visiting the dentist again! Treatment seems to take only minutes
          to complete
        </p>
      </Box>
      <Box>
        <StaticImage
          src="../images/money.png"
          alt="save money"
          placeholder="blurred"
        />
        <h3>Save Money</h3>
        <p>
          Longer single appointments and decreased need for costly anesthesia
          saves you money.
        </p>
      </Box>
      <Box>
        <StaticImage
          src="../images/time.png"
          alt="save time"
          placeholder="blurred"
        />
        <h3>Save Time</h3>
        <p>
          Many previously time-consuming procedures can now be completed in one
          appointment.{" "}
        </p>
      </Box>
    </Benefits>
  )
}
