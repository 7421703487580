/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }) => {
  // cherry widget doesnt load on subsequent page visit from other pages due to csr
  // prevLocation prevents infinite loop in peculiar situation where it goes into loop which shouldnt happen
  if (location.pathname === "/about-us/cherry-payment-plans/" && prevLocation) {
    setTimeout(() => {
      const widgetContainer = document.getElementById("all")
      if (!widgetContainer.children.length) window.location.reload()
    }, 500)
  }
}
