import { css } from "@emotion/react"
import styled from "@emotion/styled"
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@reach/accordion"
import "@reach/accordion/styles.css"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

// default button remove browser styles
export const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.62em;
  color: ${({ theme }) => theme.black};
  cursor: pointer;
  outline: none;
  display: inline-block;

  &:focus,
  &:hover {
    outline: none;
  }
`

const StyledButton = styled(Button)`
  line-height: 1.22em;
  padding: 8px 16px;
  transition: all 0.5s;

  &:focus,
  &:hover {
    font-weight: 700;
  }
`

export const BlackButton = styled(StyledButton)`
  border: ${({ theme }) => `1px solid ${theme.dark}`};
  color: ${({ theme }) => theme.dark};

  &:focus,
  &:hover {
    box-shadow: ${({ theme }) => `inset 0 0 0 2px ${theme.dark}`};
    background: #f2f2f2;
  }
`

export const WhiteButton = styled(StyledButton)`
  border: ${({ theme }) => `1px solid ${theme.white}`};
  color: ${({ theme }) => theme.white};

  &:focus,
  &:hover {
    box-shadow: ${({ theme }) => `inset 0 0 0 2px ${theme.white}`};
    background: ${({ theme }) => theme.dark};
  }
`

export const BlueButton = styled(StyledButton)`
  border: ${({ theme }) => `1px solid ${theme.blue}`};
  color: ${({ theme }) => theme.blue};

  &:focus,
  &:hover {
    box-shadow: ${({ theme }) => `inset 0 0 0 2px ${theme.blue}`};
  }
`

export const StyledAccordionItem = ({ question, children }) => (
  <AccordionItem
    css={css`
      margin: 16px 0;

      @media (min-width: 768px) {
        &[data-state="open"] {
          box-shadow: 0px 12px 14px 5px rgb(35 35 35 / 12%);
        }
      }
    `}
  >
    <h6
      css={css`
        margin: 0;
      `}
    >
      <AccordionButton
        as={Button}
        css={theme => css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          padding: 16px 23px;
          color: ${theme.black};
          font-weight: 700;

          font-size: 1rem;
          line-height: 1.62em;
          width: 100%;
          background: ${theme.white};

          ::after {
            content: "";
            height: 6px;
            width: 6px;
            display: inline-block;
            border-left: 1px solid black;
            border-bottom: 1px solid black;
            transform: rotate(-45deg) translateY(-3px);
            margin-bottom: 2px;
          }

          &[data-state="open"] {
            color: ${theme.blue};

            ::after {
              transform: rotate(135deg) translateY(0);
              border-color: ${theme.blue};
            }
          }
        `}
      >
        {question}
      </AccordionButton>
    </h6>
    <AccordionPanel
      css={theme => css`
        padding: 16px 23px;
        background: ${theme.white};
      `}
    >
      {children}
    </AccordionPanel>
  </AccordionItem>
)

export const Faq = ({ children }) => {
  return (
    <section
      css={theme => css`
        padding: 32px 0;
        margin: 32px auto;
        max-width: 1200px;
        background: ${theme.light};

        @media (min-width: 1024px) {
          border-radius: 32px;
          margin: 64px auto;
        }
      `}
    >
      <h3
        css={css`
          margin: 0 0 16px;
          display: block;
          text-align: center;
        `}
      >
        Frequently Asked Questions
      </h3>
      <Accordion
        collapsible
        css={css`
          max-width: 811px;
          margin: 0 auto;
        `}
      >
        {children}
      </Accordion>
    </section>
  )
}

export const CallNow = () => (
  <div
    css={theme => css`
      border-radius: 32px;
      background: ${theme.blue};
      max-width: 414px;
      margin: 64px auto 0;
      padding: 32px;
    `}
  >
    <StaticImage
      src="../../images/phone2.png"
      alt="call"
      placeholder="dominantColor"
    />
    <h3
      css={theme => css`
        margin: 16px 0 0;
        color: ${theme.white};
      `}
    >
      Call us to schedule a FREE consultation.
    </h3>
    <p
      css={theme => css`
        margin: 0 0 16px;
        color: ${theme.white};
      `}
    >
      We are limited in how many consultations we can schedule during any given
      period - so make sure to schedule yours as soon as possible.
    </p>
    <WhiteButton as="a" href="tel:+17274416060">
      Call us
    </WhiteButton>
  </div>
)
